<template>
  <div>
    <Header />
    <Banner />
    <CategoriesCircle />
    <TrendingList />
    <NewList />
    <!-- <ArticleList /> -->
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Banner from "../components/Banner.vue";
import TrendingList from "../components/TrendingList.vue";
import NewList from "../components/NewList.vue";
import CategoriesCircle from "../components/CategoriesCircle.vue";
// import ArticleList from "../components/ArticleList.vue";
import Footer from "../components/Footer.vue";

export default {
  metaInfo: {
    title:
      "Dino Dapps Store - Latest & Top Blockchain Dapps List | Blockchain Dapps Store List, dApps Ranked List",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "Dino Dapps Explore NFTs, Defi Apps, NFT Marketplaces, Blockchain Games, De-Fi, Dapps On The Blockchain And More Dapp List Ranked on Dino Dapps Store.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "dapps,dapps store,store,dino,blockchain,dApps,best dapps,top dapps,dapps list,crypto,crypto dapps",
      },
      {
        property: "og:title",
        content:
          "Dino Dapps Store - Latest & Top Blockchain Dapps List | Blockchain Dapps Store List, dApps Ranked List",
        vmid: "og:title",
      },
      {
        property: "og:image",
        content: "https://dinodapps.com/hero.png",
        vmid: "og:image",
      },
      {
        property: "og:description",
        content:
          "Dino Dapps Explore NFTs, Defi Apps, NFT Marketplaces, Blockchain Games, De-Fi, Dapps On The Blockchain And More Dapp List Ranked on Dino Dapps Store.",
        vmid: "og:description",
      },
      {
        property: "twitter:title",
        content:
          "Dino Dapps Store - Latest & Top Blockchain Dapps List | Blockchain Dapps Store List, dApps Ranked List",
        vmid: "twitter:title",
      },
      {
        property: "twitter:image",
        content: "https://dinodapps.com/hero.png",
        vmid: "twitter:image",
      },
      {
        property: "twitter:description",
        content:
          "Dino Dapps Explore NFTs, Defi Apps, NFT Marketplaces, Blockchain Games, De-Fi, Dapps On The Blockchain And More Dapp List Ranked on Dino Dapps Store.",
        vmid: "twitter:description",
      },
      {
        name: "twitter:card",
        content: `summary_large_image`,
        vmid: "twitter:card",
      },
      //you can also add open graph tags here
    ],
  },
  name: "Home",
  components: {
    Header,
    Banner,
    TrendingList,
    NewList,
    CategoriesCircle,
    // ArticleList,
    Footer,
  },
  created() {
    this.$mixpanel.track("Visit Homepage");
  },
};
</script>
