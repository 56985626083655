<template>
  <div>
    <section class="margin-t-20 unList-bestSeller d-lg-none d-block">
      <!-- un-title-default -->
      <div class="un-title-default">
        <div class="text">
          <h2 class="title-gradient">New dApps</h2>
          <p>Recently added dApps over the world</p>
        </div>
        <div class="un-block-right">
          <router-link to="/search" class="icon-back" aria-label="iconBtn">
            <i class="ri-arrow-drop-right-line"></i>
          </router-link>
        </div>
      </div>

      <div class="content-list-sellers">
        <ul class="nav flex-column">
          <li class="nav-item position-relative">
            <router-link
              :to="'/dapps-detail/' + dappsAds.slug"
              class="nav-link item-user-seller rainbow"
              data-label="Ads"
            >
              <div class="item-user-img">
                <picture>
                  <source
                    :srcset="
                      !dappsAds.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          dappsAds?.icon_url
                        : dappsAds?.icon_url
                    "
                    @error="() => (dappsAds.icon_url = '/images/no-images.png')"
                    type="image/webp"
                  />
                  <img
                    class="avt-img-small"
                    v-if="dappsAds.icon_url"
                    :src="
                      !dappsAds.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          dappsAds?.icon_url
                        : dappsAds?.icon_url
                    "
                    @error="() => (dappsAds.icon_url = '/images/no-images.png')"
                    alt=""
                  />
                </picture>
                <div class="txt-user">
                  <p>
                    {{ dappsAds.name }}<i class="ri-checkbox-circle-fill"></i>
                  </p>
                  <span
                    class="
                      text-dark
                      d-flex
                      flex-wrap
                      justify-content-start
                      align-items-center
                      gap-2
                    "
                  >
                    <span
                      v-for="(network, index) in dappsAds.networks"
                      :key="index"
                      class="d-inline-block"
                    >
                      <img
                        :src="network.logo_url"
                        width="20"
                        height="20"
                        alt=""
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div class="other-option">
                <button type="button" class="btn btn-box-check">
                  <img src="/images/icons/ads.svg" alt="" />
                </button>
              </div>
            </router-link>
          </li>
          <li
            class="nav-item position-relative"
            v-for="(newest, index) in dappsNewest.slice(0, 5)"
            :key="index"
          >
            <router-link
              :to="'/dapps-detail/' + newest.slug"
              class="nav-link item-user-seller"
            >
              <div class="item-user-img">
                <picture>
                  <source
                    :srcset="
                      !newest.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          newest?.icon_url
                        : newest?.icon_url
                    "
                    @error="() => (newest.icon_url = '/images/no-images.png')"
                    type="image/webp"
                  />
                  <img
                    class="avt-img-small"
                    :src="
                      !newest.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          newest?.icon_url
                        : newest?.icon_url
                    "
                    @error="() => (newest.icon_url = '/images/no-images.png')"
                    alt=""
                  />
                </picture>
                <div class="txt-user">
                  <p>
                    {{ newest.name }}<i class="ri-checkbox-circle-fill"></i>
                  </p>
                  <span
                    class="
                      text-dark
                      d-flex
                      flex-wrap
                      justify-content-start
                      align-items-center
                      gap-2
                    "
                  >
                    <span
                      v-for="(network, index) in newest.networks"
                      :key="index"
                      class="d-inline-block"
                    >
                      <img
                        :src="network.logo_url"
                        width="20"
                        height="20"
                        alt=""
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div class="other-option">
                <button type="button" class="btn btn-box-check">
                  <input type="checkbox" checked />
                  <div class="icon-box">
                    <i class="ri-add-fill"></i>
                  </div>
                </button>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="py-5 unList-bestSeller d-lg-block d-none bg-curly">
      <section class="un-section-seller margin-t-20 container">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="fw-bolder title-gradient">New dApps</h2>
            <p>New dApps over the world</p>
          </div>
          <router-link
            to="/search"
            class="
              btn btn-dark
              rounded-pill
              px-4
              d-flex
              justify-content-between
              gap-2
              align-items-center
            "
            aria-label="iconBtn"
          >
            <span>View All</span> <i class="ri-arrow-right-line"></i>
          </router-link>
        </div>
        <!-- un-block-auther -->
        <div class="un-block-creators margin-t-10">
          <div class="row col-12">
            <div class="col-3 mt-4">
              <div class="un-item-seller" style="padding: 0px !important">
                <router-link
                  :to="'/dapps-detail/' + dappsAds.slug"
                  class="
                    card card-custom
                    bg-gradient-dark
                    w-100
                    border-1
                    rounded-10
                    text-decoration-none
                  "
                  data-label="Ads"
                >
                  <div class="card-header rounded-10 py-3">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="number">01</div>
                      <div class="media-profile">
                        <figure class="image-avatar">
                          <picture>
                            <source
                              :srcset="
                                !dappsAds.icon_url?.startsWith('/images')
                                  ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                    dappsAds?.icon_url
                                  : dappsAds?.icon_url
                              "
                              class="rounded-10"
                              @error="
                                () =>
                                  (dappsAds.icon_url = '/images/no-images.png')
                              "
                              type="image/webp"
                            />
                            <img
                              class="rounded-10"
                              :src="
                                !dappsAds.icon_url?.startsWith('/images')
                                  ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                    dappsAds?.icon_url
                                  : dappsAds?.icon_url
                              "
                              @error="
                                () =>
                                  (dappsAds.icon_url = '/images/no-images.png')
                              "
                              alt=""
                            />
                          </picture>
                          <div class="icon-verify">
                            <i class="ri-checkbox-circle-fill"></i>
                          </div>
                        </figure>
                        <div class="text">
                          <h3>{{ dappsAds.name }}</h3>
                          <p v-if="dappsAds.tagline != null">
                            {{ dappsAds.tagline | truncateFirst(12, "...") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="text-dark">Network Support</div>
                    <span
                      class="
                        text-dark
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                        gap-2
                      "
                    >
                      <span
                        v-for="(network, index) in dappsAds.networks"
                        :key="index"
                        class="d-inline-block"
                      >
                        <img
                          :src="network.logo_url"
                          width="20"
                          height="20"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </router-link>
              </div>
            </div>
            <div
              class="col-3 mt-4"
              v-for="(newest, newIndex) in dappsNewest"
              :key="newIndex"
            >
              <div class="un-item-seller" style="padding: 0px !important">
                <router-link
                  :to="'/dapps-detail/' + newest.slug"
                  class="
                    card
                    bg-light
                    w-100
                    border-1
                    rounded-10
                    text-decoration-none
                  "
                >
                  <div class="card-header rounded-10 py-3">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="number">0{{ newIndex + 2 }}</div>
                      <div class="media-profile">
                        <figure class="image-avatar">
                          <picture>
                            <source
                              :srcset="
                                !newest.icon_url?.startsWith('/images')
                                  ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                    newest?.icon_url
                                  : newest?.icon_url
                              "
                              @error="
                                () =>
                                  (newest.icon_url = '/images/no-images.png')
                              "
                              class="rounded-10"
                              type="image/webp"
                            />
                            <img
                              class="rounded-10"
                              :src="
                                !newest.icon_url?.startsWith('/images')
                                  ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                    newest?.icon_url
                                  : newest?.icon_url
                              "
                              @error="
                                () =>
                                  (newest.icon_url = '/images/no-images.png')
                              "
                              alt=""
                            />
                          </picture>
                          <div class="icon-verify">
                            <i class="ri-checkbox-circle-fill"></i>
                          </div>
                        </figure>
                        <div class="text">
                          <h3>{{ newest.name | truncateFirst(16, "...") }}</h3>
                          <p v-if="newest.tagline != null">
                            {{ newest.tagline | truncateFirst(12, "...") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="text-dark">Network Support</div>
                    <span
                      class="
                        text-dark
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                        gap-2
                      "
                    >
                      <span
                        v-for="(network, index) in newest.networks"
                        :key="index"
                        class="d-inline-block"
                      >
                        <img
                          :src="network.logo_url"
                          width="20"
                          height="20"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewList",
  data() {
    return {
      dappsNewest: [],
      dappsAds: [],
    };
  },
  mounted() {
    this.getNewest();
    this.getAds();
  },
  methods: {
    async getNewest() {
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/product/newest")
          .then((response) => (this.dappsNewest = response.data.data));
      } catch (e) {
        console.log("Caught Error");
      }
    },
    async getAds() {
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/product/ads-item")
          .then((response) => {
            this.dappsAds = response.data.data[0];
            // console.log(response.data.data[0]);
          });
      } catch (e) {
        console.log("Caught Error");
      }
    },
  },
  computed: {
    // filterednewest() {
    //   return this.dappsNewest.data.filter(d => d.is_newest === 1).slice(0,6);
    // }
    // filterednewest(){
    // return this.dappsNewest.filter(dapps => {
    //     return dapps.filter(p => {
    //         return this.id.includes(p.name);
    //     }).length>0;//return only filled arrays
    // });
    //   const prod = this.dappsNewest.filter(dapps => dapps.is_newest === 1);
    //   return prod
    // }
  },
};
</script>

<style scoped>
.card-custom::before {
  position: absolute;
  top: -3px;
  right: 3px;
  content: "";
  background: #04a759;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.card-custom::after {
  position: absolute;
  content: attr(data-label);
  border-radius: 4px;
  top: -15px;
  right: -1px;
  padding: 0.2rem;
  width: 6rem;
  background: #059650;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  box-shadow: 4px 4px 15px rgb(26 35 126 / 20%);
}

.bg-curly {
  background-color: #14151c;
  opacity: 1;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #14151c 40px
    ),
    repeating-linear-gradient(#1f212555, #1f2125);
}
.bg-isometric {
  background-color: #14151c;
  opacity: 1;
  background-image: linear-gradient(
      30deg,
      #1f2125 12%,
      transparent 12.5%,
      transparent 87%,
      #1f2125 87.5%,
      #1f2125
    ),
    linear-gradient(
      150deg,
      #1f2125 12%,
      transparent 12.5%,
      transparent 87%,
      #1f2125 87.5%,
      #1f2125
    ),
    linear-gradient(
      30deg,
      #1f2125 12%,
      transparent 12.5%,
      transparent 87%,
      #1f2125 87.5%,
      #1f2125
    ),
    linear-gradient(
      150deg,
      #1f2125 12%,
      transparent 12.5%,
      transparent 87%,
      #1f2125 87.5%,
      #1f2125
    ),
    linear-gradient(
      60deg,
      #1f212577 25%,
      transparent 25.5%,
      transparent 75%,
      #1f212577 75%,
      #1f212577
    ),
    linear-gradient(
      60deg,
      #1f212577 25%,
      transparent 25.5%,
      transparent 75%,
      #1f212577 75%,
      #1f212577
    );
  background-size: 80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}
</style>