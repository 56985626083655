var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multi-bg py-4"},[_c('section',{staticClass:"margin-t-20 stories-section d-lg-none d-block"},[_c('div',{staticClass:"un-title-default"},[_vm._m(0),_c('div',{staticClass:"un-block-right"},[_c('router-link',{staticClass:"icon-back",attrs:{"to":"/category","aria-label":"iconBtn"}},[_c('i',{staticClass:"ri-arrow-drop-right-line"})])],1)]),_c('div',{staticClass:"display-stories margin-t-20"},[_c('div',{staticClass:"swiper myStories swiper-initialized swiper-horizontal swiper-free-mode swiper-android swiper-backface-hidden"},[_c('div',{staticClass:"swiper-wrapper wrapper-stories stories user-icon carousel snapgram",staticStyle:{"transform":"translate3d(0px, 0px, 0px)","transition-duration":"0ms","display":"flex","flex-direction":"row","overflow-x":"auto","fler-wrap":"nowrap"},attrs:{"id":"stories"}},_vm._l((_vm.categoryLists),function(category,index){return _c('div',{key:index,staticClass:"story swiper-slide swiper-slide-active",attrs:{"data-id":category.id,"data-photo":category.logo_url,"role":"group","aria-label":"1 / 7"}},[_c('router-link',{staticClass:"item-link",attrs:{"to":'/category-choose/' + category.slug}},[_c('span',{staticClass:"item-preview"},[_c('img',{attrs:{"lazy":"eager","src":!category?.logo_url?.startsWith('/images')
                      ? 'https://api-dapps.dinowallet.org/images/category/' +
                        category?.logo_url
                      : category?.logo_url},on:{"error":() => (category.logo_url = '/images/no-images.png')}})]),_c('span',{staticClass:"info",attrs:{"itemprop":"author","itemscope":"","itemtype":"http://schema.org/Person"}},[_c('strong',{staticClass:"name",attrs:{"itemprop":"name"}},[_vm._v(_vm._s(category.name))])])])],1)}),0),_c('span',{staticClass:"swiper-notification",attrs:{"aria-live":"assertive","aria-atomic":"true"}})])])]),_c('div',{staticClass:"unList-creatores collection-creatores container py-3 d-lg-block d-none"},[_c('div',{staticClass:"content-list-creatores"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._m(1),_c('router-link',{staticClass:"btn btn-dark rounded-pill px-4 d-flex justify-content-between gap-2 align-items-center",attrs:{"to":"/category","aria-label":"iconBtn"}},[_c('span',[_vm._v("View All")]),_vm._v(" "),_c('i',{staticClass:"ri-arrow-right-line"})])],1),_c('ul',{staticClass:"nav row col-12 align-items-center w-100 m-0"},_vm._l((_vm.categoryLists),function(category,index){return _c('li',{key:index,staticClass:"nav-item col-4"},[_c('div',{staticClass:"nav-link border-bottom-0 visited",staticStyle:{"padding":"20px 0px !important"}},[_c('div',{staticClass:"collection-img"},_vm._l((category.limit_products),function(products,index){return _c('picture',{key:index,staticClass:"img-cot border-gradient h-auto border border-gray bg-light shadow-sm p-2"},[_c('router-link',{attrs:{"to":'/dapps-detail/' + products.slug,"aria-label":products.slug}},[_c('source',{staticClass:"img-fluid rounded-circle",attrs:{"srcset":!products.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          products?.icon_url
                        : products?.icon_url,"width":"59.6","height":"59.6","type":"image/webp"},on:{"error":() => (products.icon_url = '/images/no-images.png')}}),_c('img',{staticClass:"img-fluid rounded-circle",attrs:{"src":!products.icon_url?.startsWith('/images')
                        ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                          products?.icon_url
                        : products?.icon_url,"width":"59.6","height":"59.6","alt":""},on:{"error":() => (products.icon_url = '/images/no-images.png')}})])],1)}),0),_c('router-link',{staticClass:"d-flex align-items-center justify-content-between border border-gray shadow-sm p-3 bg-light rounded-10 text-decoration-none",attrs:{"to":"/"}},[_c('div',{staticClass:"item-user-img"},[_c('div',{staticClass:"wrapper-image"},[_c('picture',[_c('source',{attrs:{"srcset":!category.logo_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/category/' +
                            category?.logo_url
                          : category?.logo_url,"type":"image/webp"},on:{"error":() => (category.logo_url = '/images/no-images.png')}}),_c('img',{staticClass:"avt-img",attrs:{"src":!category.logo_url?.startsWith('/images')
                          ? 'https://api-dapps.dinowallet.org/images/category/' +
                            category?.logo_url
                          : category?.logo_url,"alt":""},on:{"error":() => (category.logo_url = '/images/no-images.png')}})]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"ri-checkbox-circle-fill"})])]),_c('div',{staticClass:"txt-user"},[_c('div',[_vm._v(_vm._s(category.name))])])]),_c('div',{staticClass:"other-option"},[_c('div',{staticClass:"color-text rounded-pill bg-snow btn-xs-size text-white"},[_vm._v(" "+_vm._s(category.products_count)+" Dapps ")])])])],1)])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('h2',{staticClass:"title-gradient"},[_vm._v("Category List")]),_c('p',[_vm._v("See all category list")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"fw-bolder title-gradient"},[_vm._v("Popular Category")]),_c('p',[_vm._v("Sort by Dapps Count already listed")])])
}]

export { render, staticRenderFns }