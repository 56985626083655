<template>
  <div>
    <section class="padding-t-20 unList-bestSeller bg-light d-lg-none d-block">
      <!-- un-title-default -->
      <div class="un-title-default">
        <div class="text">
          <h2 class="title-gradient">Trending dApps</h2>
          <p>Trending dApps over the world</p>
        </div>
        <div class="un-block-right">
          <router-link to="/search" class="icon-back" aria-label="iconBtn">
            <i class="ri-arrow-drop-right-line"></i>
          </router-link>
        </div>
      </div>

      <div class="content-list-sellers z-10">
        <ul class="nav flex-column">
          <li
            class="nav-item"
            v-for="(trending, index) in dappsTrending"
            :key="index"
          >
            <router-link
              :to="'/dapps-detail/' + trending.slug"
              class="nav-link item-user-seller bg-light"
            >
              <div class="item-user-img">
                <picture>
                  <source :srcset="trending.icon_url" type="image/webp" />
                  <img
                    class="avt-img-small"
                    v-if="trending.icon_url"
                    :src="trending.icon_url"
                    @error="() => (trending.icon_url = null)"
                    alt=""
                  />
                  <img
                    class="avt-img-small"
                    v-else
                    src="/images/no-images.png"
                    alt=""
                  />
                </picture>
                <div class="txt-user">
                  <p>
                    {{ trending.name }}<i class="ri-checkbox-circle-fill"></i>
                  </p>
                  <span
                    class="
                      text-dark
                      d-flex
                      flex-wrap
                      justify-content-start
                      align-items-center
                      gap-2
                    "
                  >
                    <span
                      v-for="(network, index) in trending.networks"
                      :key="index"
                      class="d-inline-block"
                    >
                      <img
                        :src="network.logo_url"
                        width="20"
                        height="20"
                        alt=""
                      />
                    </span>
                  </span>
                </div>
              </div>
              <div class="other-option">
                <button type="button" class="btn btn-box-check">
                  <input type="checkbox" checked />
                  <div class="icon-box">
                    <i class="ri-add-fill"></i>
                  </div>
                </button>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <section class="py-5 bg-light unList-bestSeller d-lg-block d-none">
      <section class="un-section-seller margin-t-20 container">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h2 class="fw-bolder title-gradient">Trending dApps</h2>
            <p>Sort by Dapps Trending</p>
          </div>
          <router-link
            to="/search"
            class="
              btn btn-dark
              rounded-pill
              px-4
              d-flex
              justify-content-between
              gap-2
              align-items-center
            "
            aria-label="iconBtn"
          >
            <span>View All</span> <i class="ri-arrow-right-line"></i>
          </router-link>
        </div>

        <!-- un-block-auther -->
        <div class="un-block-creators margin-t-20">
          <div class="row col-12">
            <div
              class="col-3"
              v-for="(trending, index) in dappsTrending"
              :key="index"
            >
              <div class="un-item-seller" style="padding: 0px !important">
                <router-link
                  :to="'/dapps-detail/' + trending.slug"
                  class="card w-100 rounded-10 text-decoration-none"
                >
                  <div class="card-header bg-white rounded-10 py-3">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="number">0{{ index + 1 }}</div>
                      <div class="media-profile">
                        <figure class="image-avatar">
                          <picture>
                            <source
                              :srcset="
                                !trending.icon_url?.startsWith('/images')
                                  ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                    trending?.icon_url
                                  : trending?.icon_url
                              "
                              class="rounded-10"
                              @error="
                                () =>
                                  (trending.icon_url = '/images/no-images.png')
                              "
                              type="image/webp"
                            />
                            <img
                              class="rounded-10"
                              :src="
                                !trending.icon_url?.startsWith('/images')
                                  ? 'https://api-dapps.dinowallet.org/images/icon-dapps/' +
                                    trending?.icon_url
                                  : trending?.icon_url
                              "
                              @error="
                                () =>
                                  (trending.icon_url = '/images/no-images.png')
                              "
                              alt=""
                            />
                          </picture>
                          <div class="icon-verify">
                            <i class="ri-checkbox-circle-fill"></i>
                          </div>
                        </figure>
                        <div class="text">
                          <h3>{{ trending.name }}</h3>
                          <p>{{ trending.tagline | truncate(16) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="text-dark">Network Support</div>
                    <span
                      class="
                        text-dark
                        d-flex
                        flex-wrap
                        justify-content-start
                        align-items-center
                        gap-2
                      "
                    >
                      <span
                        v-for="(network, index) in trending.networks"
                        :key="index"
                        class="d-inline-block"
                      >
                        <img
                          :src="network.logo_url"
                          width="20"
                          height="20"
                          alt=""
                        />
                      </span>
                    </span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TrendingList",
  data() {
    return {
      dappsTrending: [],
    };
  },
  mounted() {
    this.getTrending();
  },
  methods: {
    async getTrending() {
      try {
        await axios
          .get(process.env.VUE_APP_API + "api/product/trending")
          .then((response) => (this.dappsTrending = response.data.data));
      } catch (e) {
        console.log("Caught Error");
      }
    },
  },
  filters: {
    truncate(string, value) {
      return (string || "").substring(0, value) + "…";
    },
  },
  computed: {
    // filteredTrending() {
    //   return this.dappsTrending.data.filter(d => d.is_trending === 1).slice(0,6);
    // }
    // filteredTrending(){
    // return this.dappsTrending.filter(dapps => {
    //     return dapps.filter(p => {
    //         return this.id.includes(p.name);
    //     }).length>0;//return only filled arrays
    // });
    //   const prod = this.dappsTrending.filter(dapps => dapps.is_trending === 1);
    //   return prod
    // }
  },
};
</script>
