<template>
  <div id="a">
    <section class="unSwiper-cards margin-b-20 d-lg-none d-block">
      <!-- un-title-default -->
      <div class="margin-t-20 px-3">
        <swiper :options="swiperOption2" ref="swiper" class="swiper">
          <swiper-slide v-for="(banner, index) in bannerLists" :key="index">
            <a :href="banner?.link">
              <img
                :src="
                  !banner.photo?.startsWith('/images')
                    ? 'https://api-dapps.dinowallet.org/images/banner/' +
                      banner?.photo
                    : banner?.photo
                "
                class="rounded-10 img-fluid"
                alt=""
              />
            </a>
          </swiper-slide>

          <template v-slot:button-prev>
            <div
              @click="$refs.swiper.swiperInstance.slidePrev()"
              class="swiper-button-prev"
            ></div>
          </template>
          <template v-slot:button-next>
            <div
              @click="$refs.swiper.swiperInstance.slideNext()"
              class="swiper-button-next"
            ></div>
          </template>
        </swiper>
      </div>
    </section>

    <section class="unSwiper-cards bg-light d-lg-block d-none">
      <div class="container pb-1">
        <div class="un-block-cards">
          <center>
            <div class="bk-slider">
              <swiper
                :options="swiperOption"
                id="homeSlider"
                ref="swiper2"
                class="swiper item-card-nft"
              >
                <swiper-slide>
                  <a :href="bannerLists[0]?.link" aria-label="banner1">
                    <div v-if="showImg">
                      <img
                        :src="imgUrl"
                        class="rounded-10"
                        width="500"
                        height="183"
                        alt=""
                      />
                    </div>
                  </a>
                </swiper-slide>
                <swiper-slide>
                  <a :href="bannerLists[1]?.link" aria-label="banner2">
                    <div v-if="showImg">
                      <img
                        :src="imgUrl2"
                        class="rounded-10"
                        width="500"
                        height="183"
                        alt=""
                      />
                    </div>
                  </a>
                </swiper-slide>
                <swiper-slide>
                  <a :href="bannerLists[2]?.link" aria-label="banner3">
                    <div v-if="showImg">
                      <img
                        :src="imgUrl3"
                        class="rounded-10"
                        width="500"
                        height="183"
                        alt=""
                      />
                    </div>
                  </a>
                </swiper-slide>

                <template v-slot:button-prev>
                  <div
                    @click="$refs.swiper2.swiperInstance.slidePrev()"
                    class="swiper-button-prev"
                  ></div>
                </template>
                <template v-slot:button-next>
                  <div
                    @click="$refs.swiper2.swiperInstance.slideNext()"
                    class="swiper-button-next"
                  ></div>
                </template>
              </swiper>
            </div>
          </center>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.min.css";

export default {
  name: "Banner",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      bannerLists: [],
      swiperOption: {
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: { slidesPerView: 1, spaceBetween: 14, initialSlide: 1 },
          480: { slidesPerView: 1, spaceBetween: 14, initialSlide: 1 },
          640: { slidesPerView: 1, spaceBetween: 1, initialSlide: 1 },
          720: { slidesPerView: 3, spaceBetween: 180, initialSlide: 2 },
        },
      },
      swiperOption2: {
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      showImg: false,
      load: false,
      imgUrl: null,
      imgUrl2: null,
      imgUrl3: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(process.env.VUE_APP_API + "api/banner").then((response) => {
        this.bannerLists = response.data.data;
        this.load = true;
        let images1 =
          "https://api-dapps.dinowallet.org/images/banner/" +
          response.data.data[0].photo;
        let images2 =
          "https://api-dapps.dinowallet.org/images/banner/" +
          response.data.data[1].photo;
        let images3 =
          "https://api-dapps.dinowallet.org/images/banner/" +
          response.data.data[2].photo;
        if (images1 != null) {
          let img = new Image();

          img.onload = () => {
            this.imgUrl = img.src;
            this.imgUrl2 = img.src1;
            this.imgUrl3 = img.src2;
            this.showImg = true;
          };
          img.src = images1;
          img.src1 = images2;
          img.src2 = images3;
        }
        // img.src =
        //   "https://api-dapps.dinowallet.org/images/banner/6385d94a181cb1669716298.png";
      });
    });
    // this.getBannerList();
  },
  methods: {
    // getBannerList() {
    // },
  },
};
</script>

<style scoped>
.swiper-slide-active {
  transform: scale(1);
}

/* .swiper-backface-hidden .swiper-slide {
  width: 30vw !important;
} */
.item-card-nft .big-image {
  object-fit: fill;
}
.un-block-cards .item-card-nft {
  height: 40vh !important;
}
#homeSlider .swiper-slide-active {
  transform: scale(1.2);
  z-index: 2;
  margin-left: -62px !important;
}
.bk-slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  /* padding: 50px 0 100px; */
}
#homeSlider .swiper-slide.swiper-slide-prev {
  margin-left: 78px;
  /* margin-right: 65px !important; */
}

#homeSlider .swiper-slide.swiper-slide-next {
  margin-left: -93px;
  z-index: 1;
  /* margin-right: 65px !important; */
}

.unSwiper-cards .swiper-button-prev {
  display: flex !important;
}
.unSwiper-cards .swiper-button-next {
  display: flex !important;
}
</style>
